// Generated by Framer (3969033)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["NnLH_QIa0", "UAnO2h4EB", "L21dtbEjQ", "xqzJKHC0y"];

const serializationHash = "framer-7odyH"

const variantClassNames = {L21dtbEjQ: "framer-v-1cj5cyb", NnLH_QIa0: "framer-v-sw20ro", UAnO2h4EB: "framer-v-1947bk", xqzJKHC0y: "framer-v-zr7vez"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 5, ease: [0, 0, 1, 1], type: "tween"}

const transition2 = {duration: 0, type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {animated: "UAnO2h4EB", finished: "xqzJKHC0y", initial: "NnLH_QIa0", waiting: "L21dtbEjQ"}

const getProps = ({click, height, id, width, ...props}) => { return {...props, KsNiPOVxJ: click ?? props.KsNiPOVxJ, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "NnLH_QIa0"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, KsNiPOVxJ, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "NnLH_QIa0", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapidnks6 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (KsNiPOVxJ) {const res = await KsNiPOVxJ(...args);
if (res === false) return false;}
})

const onAppearryjxz9 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("UAnO2h4EB"), 100)
})

useOnVariantChange(baseVariant, {default: onAppearryjxz9, L21dtbEjQ: undefined, UAnO2h4EB: undefined, xqzJKHC0y: undefined})

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (["L21dtbEjQ", "xqzJKHC0y"].includes(baseVariant)) return false;
return true
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1} {...addPropertyOverrides({L21dtbEjQ: {value: transition2}, xqzJKHC0y: {value: transition2}}, baseVariant, gestureVariant)}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-sw20ro", className, classNames)} data-framer-name={"initial"} data-highlight layoutDependency={layoutDependency} layoutId={"NnLH_QIa0"} onTap={onTapidnks6} ref={ref ?? ref1} style={{backgroundColor: "rgb(221, 241, 251)", ...style}} variants={{xqzJKHC0y: {backgroundColor: "rgb(3, 155, 234)"}}} {...addPropertyOverrides({L21dtbEjQ: {"data-framer-name": "waiting"}, UAnO2h4EB: {"data-framer-name": "animated"}, xqzJKHC0y: {"data-framer-name": "finished"}}, baseVariant, gestureVariant)}>{isDisplayed() && (<motion.div className={"framer-1n1co9v"} data-framer-name={"loading"} layoutDependency={layoutDependency} layoutId={"vd0thLzx0"} style={{backgroundColor: "rgb(3, 155, 234)"}}/>)}</motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-7odyH.framer-1kvvbaa, .framer-7odyH .framer-1kvvbaa { display: block; }", ".framer-7odyH.framer-sw20ro { cursor: pointer; height: 5px; overflow: hidden; position: relative; width: 81px; }", ".framer-7odyH .framer-1n1co9v { bottom: 0px; flex: none; left: 0px; overflow: visible; position: absolute; top: 0px; width: 0%; }", ".framer-7odyH.framer-v-1947bk .framer-1n1co9v { width: 100%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 5
 * @framerIntrinsicWidth 81
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"UAnO2h4EB":{"layout":["fixed","fixed"]},"L21dtbEjQ":{"layout":["fixed","fixed"]},"xqzJKHC0y":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"KsNiPOVxJ":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameraL710spLx: React.ComponentType<Props> = withCSS(Component, css, "framer-7odyH") as typeof Component;
export default FrameraL710spLx;

FrameraL710spLx.displayName = "slider-nav";

FrameraL710spLx.defaultProps = {height: 5, width: 81};

addPropertyControls(FrameraL710spLx, {variant: {options: ["NnLH_QIa0", "UAnO2h4EB", "L21dtbEjQ", "xqzJKHC0y"], optionTitles: ["initial", "animated", "waiting", "finished"], title: "Variant", type: ControlType.Enum}, KsNiPOVxJ: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FrameraL710spLx, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})